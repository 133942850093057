import { useEffect } from "react";
import { useSelector } from "react-redux";

const useIdentifyUser = () => {
  const user = useSelector((state) => state.user.user); 

  useEffect(() => {
    if (user && window.Brevo) {
      // Identifier l'utilisateur avec son email et ses attributs
      window.Brevo.push(function () {
        window.Brevo.identify({
          identifiers: {
            email_id: user.email, // L'adresse e-mail de l'utilisateur, obligatoire
          },
          attributes: {
            FIRSTNAME: user.firstname, // Optionnel : prénom
            LASTNAME: user.lastname,   // Optionnel : nom
            
          },
        });
      });
    }
  }, [user]); 
};

export default useIdentifyUser;
