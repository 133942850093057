import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'fast-modal-library';
import HidePasswordIcon from '../components/HidePasswordIcon';

const regexPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-+.]).{8,}$/;

const apiPrefix = process.env.REACT_APP_API_PREFIX;

/**
 * @file
 * @module Pages/ResetPassword
 * @description ResetPassword est une page qui permet à un utilisateur de réinitialiser son mot de passe.
 * - L'utilisateur doit saisir un nouveau mot de passe et le confirmer.
 * - Lorsque l'utilisateur soumet le formulaire, un e-mail de confirmation est envoyé.
 * - Cette page utilise le hook useState de React pour gérer l'état des champs de saisie et de l'affichage de la modale.
 * - Cette page utilise le hook useEffect de React pour vérifier la validité du token de réinitialisation de mot de passe.
 * - Cette page utilise la fonction fetch de JavaScript pour envoyer une demande de réinitialisation de mot de passe à l'API.
 */

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const navigate = useNavigate();
    const { token } = useParams();

    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await fetch(apiPrefix + `reset/${token}`);
                if (response.status !== 200) {
                    navigate('/error');
                }
            } catch (error) {
                navigate('/error');
            }
        };

        checkToken();
    }, [token, navigate]);

    const hide = () => {
        setShowModal(false);
        if (isSuccess) {
            navigate('/signin');
        }
    };

    const resetPassword = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setMessage('Les mots de passe ne correspondent pas');
            setShowModal(true);
            return;
        }

        if (!regexPassword.test(password)) {
            setMessage('Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial.');
            setShowModal(true);
            return;
        }

        try {
            const response = await fetch(apiPrefix + `reset/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ password })
            });

            const data = await response.json();

            if (response.ok) {
                setIsSuccess(true);
            }

            setMessage(data.message);
            setShowModal(true);
        } catch (error) {
            setMessage('Une erreur est survenue lors de la réinitialisation du mot de passe.');
            setShowModal(true);
        }
    };

    return (
        <div className='newpass-container'>
            <h2>Réinitialiser le mot de passe</h2>
            <form onSubmit={resetPassword}>
                <div className='password-input'>
                    <input type={showPassword ? 'text' : 'password'} placeholder="Nouveau mot de passe" value={password} onChange={e => setPassword(e.target.value)} required />
                    <HidePasswordIcon showPassword={showPassword} setShowPassword={setShowPassword} />
                </div>
                <input type={showPassword ? 'text' : 'password'} placeholder="Confirmer le nouveau mot de passe" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required />
                <button type="submit">Réinitialiser le mot de passe</button>
            </form>
            {showModal && (
                <Modal contentModal={message} hide={hide} />
            )}
        </div>
    );
};

export default ResetPassword;
