import React from "react";
import logo from "../asset/logo.webp"
import banniere from "../asset/bannierepopup.webp"

const ConsentPopup = ({ onAccept, onDecline }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "white",
        borderRadius: "15px",
        boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
        width: "90%", // Largeur adaptative
        maxWidth: "500px", // Largeur maximale pour desktop
        zIndex: 1000,
        fontFamily: "Arial, sans-serif",
        overflow: "hidden",
      }}
    >
      {/* Logo */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "15px 0", // Espacement autour du logo
          backgroundColor: "white",
        }}
      >
        <img
          src={logo}
          alt="CCPM logo"
          style={{
            width: "100px", // Taille du logo
            height: "auto", 
          }}
        />
      </div>

      {/* Image */}
      <div>
        <img
          src={banniere} 
          alt="Communauté joyeuse"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </div>

      {/* Texte */}
      <div style={{ padding: "20px" }}>
        <h3 style={{ fontSize: "18px", color: "#333", marginBottom: "15px", textAlign: "center" }}>
          ✨ <strong>Votre quotidien plus simple et plus joyeux !</strong>
        </h3>
        <p style={{ fontSize: "14px", color: "#666", marginBottom: "20px", textAlign: "center" }}>
          Rejoignez notre communauté et soyez les premiers informés des offres
          exclusives et des réductions jusqu’à <strong>60%</strong> sur les
          Parcs, vacances, cinéma, spectacles…
        </p>

        {/* Options */}
        <div style={{ textAlign: "center" }}>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "14px",
              color: "#333",
              marginBottom: "15px",
            }}
          >
            <input
              type="checkbox"
              style={{
                marginRight: "10px",
                width: "18px",
                height: "18px",
              }}
              onChange={onAccept}
            />
            Oui, je le veux
          </label>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "14px",
              color: "#333",
            }}
          >
            <input
              type="checkbox"
              style={{
                marginRight: "10px",
                width: "18px",
                height: "18px",
              }}
              onChange={onDecline}
            />
            Non, je préfère passer à côté
          </label>
        </div>
      </div>
    </div>
  );
};

export default ConsentPopup;
