import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../feature/user.Slice';
import { useNavigate } from 'react-router-dom';
import HidePasswordIcon from '../components/HidePasswordIcon';


/**
 * @file
 * @module Pages/Signin
 * @description Signin est une page qui permet à l'utilisateur de se connecter à son compte.
 * - Elle contient un formulaire où l'utilisateur peut entrer son nom d'utilisateur et son mot de passe.
 * - Lorsque le formulaire est soumis, une requête POST est envoyée à l'API pour authentifier l'utilisateur.
 * - Si l'authentification réussit, l'utilisateur est redirigé vers la page d'accueil.
 * - Si l'authentification échoue, un message d'erreur est affiché.
 *
 */
const apiPrefix = process.env.REACT_APP_API_PREFIX;



const regexEmail = /^[^@\s]{2,30}@[^\s@]{2,30}\.[^@\s]{2,}$/;

const regexPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-+.]).{8,}$/;


const Signin = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!regexEmail.test(username)) {
            setError("Le nom d'utilisateur doit être une adresse e-mail valide.");
            return;
        }
        if (!regexPassword.test(password)) {
            setError("Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial.");
            return;
        }

        const formData = {
            username: username,
            password: password,
        };

        try {
            const response = await fetch(apiPrefix +'login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const data = await response.json();

                if (data.user.active) {
                    dispatch(setUser({ user: data.user, token: data.token }));

                    // localStorage.setItem('user', JSON.stringify(data.user));
                    // localStorage.setItem('token', data.token);

                    navigate('/');
                } else {
                    setError('Veuillez contacter notre support à l\'adresse suivante : contact@ca-cpourmoi.com .');
                }
            } else {
                setError('Nom d\'utilisateur ou mot de passe incorrect');
            }
        } catch (error) {
            console.error('Erreur:', error);
        }
    }

    const handleForgotPassword = () => {
        navigate('/forgot');
    };

    return (
        <div className='signin-container'>
            <h2>Connexion</h2>
            {error && <p>{error}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">
                        <i className="fas fa-envelope"></i> Nom d'utilisateur :
                    </label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">
                        <i className="fas fa-key"></i> Mot de passe :
                    </label>
                        <div className='password-input'>
                             <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            />
                    <HidePasswordIcon showPassword={showPassword} setShowPassword={setShowPassword} />
                    </div>
                </div>
                <button type="submit">Se connecter</button>
                <button className="forgot-password" onClick={handleForgotPassword}>Mot de passe oublié ?</button>
            </form>

        </div>
    );
}

export default Signin;