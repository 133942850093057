import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BrevoTracker from "./BrevoTracker";
import useIdentifyUser from "./BrevoIdentifyUser";
import ConsentPopup from "./BrevoConsent";

const BrevoIntegration = () => {
  const [consentGiven, setConsentGiven] = useState(false);
  const [consentDeclined, setConsentDeclined] = useState(false);
  const user = useSelector((state) => state.user.user);
 

  // Récupère le consentement déjà sauvegardé dans localStorage
  useEffect(() => {
    const savedConsent = localStorage.getItem("brevo_consent");
    if (savedConsent === "true") {
      setConsentGiven(true);
    }
  }, []);

  // URL de ton API backend pour créer le contact optin
  const apiPrefix = process.env.REACT_APP_API_PREFIX; // Assure-toi que cette variable est définie dans ton .env
  const url = `${apiPrefix}optin/create-contact-optin`;

  // Fonction appelée lorsqu'un utilisateur accepte le consentement
  const handleAccept = async () => {
    localStorage.setItem("brevo_consent", "true");
    setConsentGiven(true);
    console.log("Consentement accepté.");

    if (user) {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: user.user.username }), // Envoie l'email de l'utilisateur
        });

        if (response.ok) {
          
        } else {
          const errorData = await response.json();
          console.error("Erreur backend :", errorData);
        }
      } catch (error) {
        console.error("Erreur réseau :", error);
      }
    } else {
      console.warn("Aucun utilisateur connecté pour ajouter à la liste.");
    }
  };

  // Fonction appelée si l'utilisateur refuse le consentement
  const handleDecline = () => {
    setConsentDeclined(true);
    console.log("Consentement refusé.");
  };

  // Appel du hook pour identifier l'utilisateur seulement si le consentement est donné
  useIdentifyUser();

  return (
    <>
      {/* Charge et initialise le SDK Brevo */}
      <BrevoTracker />

      {/* Affiche la popup de consentement si non donné */}
      {user && !consentGiven && !consentDeclined && (
        <ConsentPopup onAccept={handleAccept} onDecline={handleDecline} />
      )}

      {/* Message de confirmation si le consentement a été donné */}
      {consentGiven && user && (
        <p style={{ position: "fixed", bottom: "10px", left: "10px" }}>
          {/* Merci d'avoir accepté, vous êtes maintenant ajouté à notre liste ! */}
        </p>
      )}
    </>
  );
};

export default BrevoIntegration;
