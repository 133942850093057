import React, { useState } from 'react';

const Filters = ({ columns, onChange }) => {
  const [filters, setFilters] = useState([]); // Tableau de filtres

  // Ajouter un filtre vide
  const addFilter = () => {
    setFilters([...filters, { column: '', value: '' }]);
  };

  // Mettre à jour un filtre
  const updateFilter = (index, field, newValue) => {
    const newFilters = [...filters];
    newFilters[index][field] = newValue;
    setFilters(newFilters);
    onChange(newFilters); // Remonte les filtres au parent
  };

  // Supprimer un filtre
  const removeFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
    onChange(newFilters); // Remonte les filtres au parent
  };

  return (
    <div className="filters">
      <h3>Filtres</h3>
      {filters.map((filter, index) => (
        <div key={index} className="filter-row">
          <select
            value={filter.column}
            onChange={(e) => updateFilter(index, 'column', e.target.value)}
          >
            <option value="">Sélectionner une colonne</option>
            {columns.map((col) => (
              <option key={col.value} value={col.value}>
                {col.label}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Valeur"
            value={filter.value}
            onChange={(e) => updateFilter(index, 'value', e.target.value)}
          />
          <button onClick={() => removeFilter(index)}>Supprimer</button>
        </div>
      ))}
      <button onClick={addFilter}>Ajouter un filtre</button>
    </div>
  );
};

export default Filters;
